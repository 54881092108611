import React, { useState, useEffect } from "react";
import { Button, ScreenContainer } from "../../components";
import { useTranslation } from "react-i18next";
import { PaymentModal } from "../../components/PaymentModal/PaymentModal";
import { api } from "../../api";
import { apiEndpoint } from "../../api/apiEndpoints";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const SubscriptionPlan = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [pricingPlans, setPricingPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [subscription, setSubscription] = useState(null);
  const token = localStorage.getItem("token");
  const [trialDaysLeft, setTrialDaysLeft] = useState(null);

  useEffect(() => {
    const formData = JSON.parse(localStorage.getItem("formData") || "{}");
    const createdAt = formData.createdAt;

    if (createdAt) {
      const creationDate = new Date(createdAt);
      const trialPeriod = 14 * 24 * 60 * 60 * 1000;
      const trialEndDate = new Date(creationDate.getTime() + trialPeriod);
      const currentDate = new Date();

      if (currentDate <= trialEndDate) {
        const daysLeft = Math.ceil((trialEndDate - currentDate) / (1000 * 60 * 60 * 24));
        setTrialDaysLeft(daysLeft);
      }
    }
  }, []);

useEffect(() => {
  const fetchSubscriptionData = async () => {
    setLoading(true);
    try {
      const status = getSubscriptionStatus();
      
      if (status.type === "trial") {
        setTrialDaysLeft(status.daysLeft);
      }
      
      if (status.type === "subscription") {
        setSubscription({
          status: "active",
          plan: status.plan
        });
      }
      const token = localStorage.getItem("token");
      if (token) {
        const response = await api.post(apiEndpoint.getSubscriptionDetails, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.responseStatus === 1) {
          const subscriptionData = {
            id: response.data.response.id,
            status: response.data.response.status,
            currentPeriodEnd: response.data.response.current_period_end,
            plan: {
              id: response.data.response.plan.id,
              name: getPlanNameFromInterval(response.data.response.plan.interval_count),
              amount: response.data.response.plan.amount / 100,
              currency: response.data.response.plan.currency
            }
          };


          setSubscription(subscriptionData);
          localStorage.setItem("subscriptionData", JSON.stringify(subscriptionData));
        } else {
          setSubscription(null);
          localStorage.removeItem("subscriptionData");
        }
      }

      const pricingResponse = await api.post(apiEndpoint.getStripePricing);
      if (pricingResponse.data.responseStatus === 1) {
        const sortedPlans = pricingResponse.data.response.sort((a, b) => a.price - b.price);
        setPricingPlans(sortedPlans);
      } else {
        toast.error("Failed to fetch pricing plans");
      }

    } catch (error) {
      console.error("Error loading subscription data:", error);
      toast.error("Error loading subscription data");
      setSubscription(null);
      localStorage.removeItem("subscriptionData");
    } finally {
      setLoading(false);
    }
  };

  fetchSubscriptionData();
}, [token]); 

  const getPlanNameFromInterval = (intervalCount) => {
    switch (intervalCount) {
      case 1:
        return "Monthly";
      case 3:
        return "Quarterly";
      case 12:
        return "Yearly";
      default:
        return "Monthly";
    }
  };

 
  const formatPrice = (price, currency = 'USD') => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency
    }).format(price);
  };

  const getPlanFeatures = (planName) => {
    const featureMap = {
      Monthly: t('subscription.features.monthly', { returnObjects: true }),
      Quarterly: t('subscription.features.quarterly', { returnObjects: true }),
      Yearly: t('subscription.features.yearly', { returnObjects: true })
    };
    return featureMap[planName] || [];
  };

  const isPlanMatch = (subscriptionPlan, currentPlan) => {
    if (!subscriptionPlan || !currentPlan) return false;
    
    if (subscriptionPlan.plan.id === currentPlan.stripeId) return true;
    
    const planIntervalMap = {
      'Monthly': 1,
      'Quarterly': 3,
      'Yearly': 12
    };
    
    return (
      planIntervalMap[currentPlan.name] === subscriptionPlan.plan.interval_count &&
      Math.abs(subscriptionPlan.plan.amount - (currentPlan.price * 100)) < 1
    );
  };
  
  const getSubscriptionStatus = () => {
    const subscriptionData = JSON.parse(localStorage.getItem("subscriptionData") || "null");
    const formData = JSON.parse(localStorage.getItem("formData") || "{}");

    if (subscriptionData?.status === "active") {
      return {
        isActive: true,
        type: "subscription",
        plan: subscriptionData.plan
      };
    }
    
    if (formData.createdAt) {
      const creationDate = new Date(formData.createdAt);
      const trialPeriod = 14 * 24 * 60 * 60 * 1000;
      const trialEndDate = new Date(creationDate.getTime() + trialPeriod);
      const currentDate = new Date();
      
      if (currentDate <= trialEndDate) {
        return {
          isActive: true,
          type: "trial",
          daysLeft: Math.ceil((trialEndDate - currentDate) / (1000 * 60 * 60 * 24))
        };
      }
    }
    
    return {
      isActive: false,
      type: "none"
    };
  };
  
  
  const handleManageSubscription = () => {
    navigate('/subscription/details');
  };

  const TrialBanner = () => trialDaysLeft && (
    <div className="w-full max-w-6xl mb-8 p-4 bg-blue-50 border border-blue-200 rounded-lg">
      <p className="text-center text-blue-800">
        {trialDaysLeft === 1
          ? t('subscription.trialExpiresTomorrow')
          :t('subscription.trialDaysRemaining', { days: trialDaysLeft })
        }
      </p>
    </div>
  );

  const formatRenewalDate = (timestamp) => {
    if (!timestamp) return '';
    
    const date = new Date(timestamp * 1000);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }).format(date);
  };
  
  const CurrentSubscriptionBanner = () => {
    if (!subscription) return null;
  
    const renewalDate = formatRenewalDate(subscription.currentPeriodEnd);
  if (token) {
    return (
      <div className="w-full max-w-6xl mb-8 p-4 bg-green-50 border border-green-200 rounded-lg">
        <div className="flex justify-between items-center">
          <div>
            <p className="text-green-800 font-semibold">
              {t('subscription.currentPlan', { plan: subscription.plan.name })}
            </p>
            <p className="text-green-700 text-sm">
              {t('subscription.renewalDate', { date: renewalDate })}
            </p>
          </div>
          <Button
            onClick={handleManageSubscription}
            className="bg-green-600 hover:bg-green-700 text-white px-4 py-2"
          >
            {t('subscription.manage')}
          </Button>
        </div>
      </div>
    );
  };
  };
  if (loading) {
    return (
      <ScreenContainer>
        <div className="flex justify-center items-center h-screen">
          <div className="text-xl">{t('subscription.loading')}</div>
        </div>
      </ScreenContainer>
    );
  }

  return (
    <ScreenContainer>
      <div className="w-full h-full sm:h-auto overflow-auto">
        <div className="flex flex-col items-center py-10 px-8">
          <h1 className="text-3xl font-bold text-gray-800 mb-8 text-center">
            {t('subscription.choosePlan')}
          </h1>
          <TrialBanner />
          <CurrentSubscriptionBanner />
          <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-6 w-full max-w-6xl">
            {pricingPlans.map((plan) => {
              const isCurrentPlan = subscription && isPlanMatch(subscription, plan);

              return (
                <div
                  key={plan._id}
                  className={`bg-white shadow-lg rounded-lg p-6 flex flex-col justify-between hover:shadow-xl transition-shadow duration-300 ${
                    isCurrentPlan ? 'border-2 border-green-500' : ''
                  }`}
                >
                  <div>
                    <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                      {plan.name}
                      {isCurrentPlan && (
                        <span className="text-green-500 ml-2">{t('subscription.currentPlanLabel')}</span>
                      )}
                    </h2>
                    <div className="text-3xl font-bold text-gray-800 mb-6">
                      {formatPrice(plan.price)}
                      <span className="text-base font-normal text-gray-600">
                        {" "}{plan.name.toLowerCase()}
                      </span>
                    </div>
                    <ul className="text-gray-600 mb-6 space-y-3">
                      {getPlanFeatures(plan.name).map((feature, index) => (
                        <li key={index} className="flex items-start">
                          <span className="text-green-500 mr-2">✓</span>
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                  {!isCurrentPlan && (
                    <Button
                      className="w-full bg-blue-600 hover:bg-blue-700 text-white"
                      onClick={() => {
                        if (!token) {
                          toast.warning(t('subscription.loginRequired'));
                          window.location.href = "/login";
                        } else {
                          setSelectedPlan(plan);
                        }
                      }}
                    >
                      {t('subscription.selectPlan', { plan: plan.name })}
                    </Button>
                  )}
                  {isCurrentPlan && (
                    <Button
                      className="w-full bg-green-600 hover:bg-green-700 text-white"
                      onClick={handleManageSubscription}
                    >
                      {t('subscription.manage')}
                    </Button>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <PaymentModal
        isOpen={!!selectedPlan}
        priceId={selectedPlan?._id}
        onClose={() => setSelectedPlan(null)}
      />
    </ScreenContainer>
  );
};

export default SubscriptionPlan;