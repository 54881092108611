import React from "react";
import './Footer.scss';
import { FooterLogo } from "../FooterLogo";

export const Footer = () => {
  return (
    <footer className="Footer flex-shrink-0">
      <FooterLogo />
    </footer>
  );
};
