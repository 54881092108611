import React from 'react';
import { IoIosClose } from "react-icons/io";
import { useTranslation } from "react-i18next";

const InvoicePaymentModal = ({ isOpen, onClose, invoiceLink }) => {
  const { t } = useTranslation();
  
  if (!isOpen) return null;
  
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">{t('invoice.modalTitle')}</h2>
          <button
            onClick={() => onClose(false)} 
            className="text-gray-500 hover:text-gray-700"
            aria-label={t('invoice.close')}
          >
            <IoIosClose/>
          </button>
        </div>
       
        <div className="mb-6">
          <p className="text-gray-600 mb-4">
            {t('invoice.description')}
          </p>
        </div>
        <div className="flex justify-end">
          <a
            href={invoiceLink}
            target="_blank"
            rel="noopener noreferrer"
            className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded-md transition-colors"
            onClick={() => onClose(true)}
          >
            {t('invoice.payNow')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default InvoicePaymentModal;