import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";

const checkAccess = () => {
  const token = localStorage.getItem("token");
  const subscriptionData = JSON.parse(localStorage.getItem("subscriptionData") || "null");
  const formData = JSON.parse(localStorage.getItem("formData") || "{}");
  const createdOn = formData.createdAt;

  if (!token) return false;
  
  if (subscriptionData?.status === "active") return true;

  if (createdOn) {
    const creationDate = new Date(createdOn);
    const trialPeriod = 14 * 24 * 60 * 60 * 1000; 
    const trialEndDate = new Date(creationDate.getTime() + trialPeriod);
    const currentDate = new Date();
    
    return currentDate.getTime() <= trialEndDate.getTime();
  }

  return false;
};

const ProtectedRoute = ({ element, redirectTo = "/login" }) => {
  const location = useLocation();
  const hasAccess = checkAccess();

  useEffect(() => {
    const formData = JSON.parse(localStorage.getItem("formData") || "{}");
    const createdOn = formData.createdAt;
    
    if (createdOn) {
      const creationDate = new Date(createdOn);
      const trialPeriod = 14 * 24 * 60 * 60 * 1000;
      const trialEndDate = new Date(creationDate.getTime() + trialPeriod);
      const currentDate = new Date();
      
      if (currentDate.getTime() > trialEndDate.getTime()) {
        localStorage.clear(); 
        return <Navigate to="/subscription" />;
      }
    }
  }, []);

  if (!hasAccess) {
    const token = localStorage.getItem("token");
    if (token) {
      return <Navigate to="/subscription" state={{ path: location.pathname }} />;
    }

    return <Navigate to={redirectTo} state={{ path: location.pathname }} />;
  }

  return element;
};

export default ProtectedRoute;