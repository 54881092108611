export const apiEndpoint = {
  signUp: "signup",
  signIn: "signin",
  forgotPassword: "forgotPassword",
  updateForgotPassword: "updateForgotPassword",
  verifyOtp: "verifyOtp",
  signupwithemail: "signupwithemail",
  searchExistingValues: "searchExistingValues",
  checkEmail: "checkEmail",
  resendOtpCode: "/resendOtpCode",
  addUserTreatment: "addUserTreatment",
  //   addQuestions: "/addUserTreatment",
  createClientSecretForPaymentIntent: "/createClientSecretForPaymentIntent",
  getStripePricing: "/getStripePricing",
  getSubscriptionDetails: 'getSubscriptionDetails',
  cancelSubscription: 'cancelSubscription',
  getUserProfile: 'getUserProfile',
};
