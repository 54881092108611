import React from "react";
import "./ScreenContainer.scss";
import { Header, Footer, Loader } from "..";
import cx from "classnames";

export const ScreenContainer = (props) => {
  const {
    status,
    overlay,
    containerClassName,
    screenBackgroundClassName,
    headerRightArea,
    LoaderComponent,
  } = props;
  return (
    <div className={cx("ScreenBackground h-full", screenBackgroundClassName)}>
      <Header headerRightArea={headerRightArea} />
      <div
        className={cx(
          "flex flex-col sm:flex-row items-center justify-center h-[calc(100vh_-_50px_-_54px)] lg:h-[calc(100vh_-_80px_-_80px)]",
          containerClassName
        )}
      >
        {status === "success" ? props.children : <LoaderComponent />}
      </div>
      {overlay}
      <Footer />
    </div>
  );
};

ScreenContainer.defaultProps = {
  status: "success",
  overlay: null,
  headerRightArea: null,
  LoaderComponent: Loader,
};
