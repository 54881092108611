import React from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserData } from "../../redux/userInformation/UserInfo";
import { formSchema } from "./schemas/index";
import { useTranslation } from "react-i18next";
import { ScreenContainer, TextField, ToggleButtons } from "../../components";
import "./Form.scss";

const Form = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const formData = JSON.parse(localStorage.getItem("formData"));
  const initialValues = {
    name: formData?.name ? formData?.name : "",
    age: formData?.age ? formData?.age : "",
    gender: formData?.gender ? formData?.gender : "",
    evaluation: formData?.evaluation ? formData?.evaluation : "",
    createdAt: formData?.createdAt ? formData?.createdAt : "",
  };
  const Navigate = useNavigate();
  const schema = formSchema(t);
  const {
    values,
    errors,
    handleBlur,
    handleChange: onChange,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    validateOnChange: false,
    onSubmit: (values, action) => {
      dispatch(
        setUserData({
          age: values.age,
          gender: values.gender,
          evaluation: values.evaluation,
        })
      );
      action.resetForm();
      localStorage.setItem("formData", JSON.stringify(values));
      if (values.evaluation === "chatbot") {
        const token = localStorage.getItem("token");
        if (token) {
          Navigate("/chatbot");
        } else {
          Navigate("/home/login", { state: { path: "/chatbot" } });
        }
      } else if (values.evaluation === "manual") {
        Navigate("/dignosisIntro");
      }
    },
  });

  // useEffect(() => {
  //   if (values.name && values.age && values.gender && values.evaluation) {
  //     dispatch(
  //       setUserData({
  //         age: values.age,
  //         gender: values.gender,
  //         evaluation: values.evaluation,
  //       })
  //     );
  //     setTimeout(handleSubmit, 2000);
  //   }
  // }, [values, handleSubmit, dispatch]);

  return (
    <ScreenContainer>
      <div className="FormScreenContainer">
        <TextField
          label={t("label1")}
          value={values.name}
          error={errors.name}
          handleBlur={handleBlur}
          name="name"
          onChange={onChange}
        />
        <TextField
          label={t("label2")}
          value={values.age}
          error={errors.age}
          handleBlur={handleBlur}
          name="age"
          onChange={onChange}
        />
        <ToggleButtons
          value={values.gender}
          name="gender"
          error={errors.gender}
          onChange={setFieldValue}
          buttons={[
            { label: t("genderList1"), value: "male" },
            { label: t("genderList2"), value: "female" },
          ]}
        />
        <ToggleButtons
          value={values.evaluation}
          name="evaluation"
          error={errors.evaluation}
          onChange={setFieldValue}
          buttons={[
            { label: t("manual_evaluation"), value: "manual" },
            { label: t("chatbot_evaluation"), value: "chatbot" },
          ]}
          text={t("wanna_continue_with")}
        />
        <button
          className="mt-3 custom-hover text-white py-2 rounded-md mx-2"
          onClick={handleSubmit}
        >
          {t("Continue")}
        </button>
      </div>
    </ScreenContainer>
  );
};

export default Form;
